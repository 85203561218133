<template>
  <div>
    <profile-header text="Invites">
      <template>
        <div class="fill-height text-end d-flex flex-column justify-end"> 
          <h3>
            Pod Capacity {{current}}/{{total}}
          </h3>
        </div>
      </template>
    </profile-header>

    <v-container>
      <v-row>
        <v-col>
          <div class="invite-field">
            <v-text-field outlined hide-details label="Invite with Email" />
            <v-btn block large color="black lighten-2 white--text">SEND</v-btn>
          </div>
          <div class="invite-field">
            <v-text-field outlined hide-details label="Invite with Username" />
            <v-btn block large color="black lighten-2 white--text">SEND</v-btn>
          </div>
          <div class="invite-field">
            <v-text-field outlined hide-details label="Invite via Text" />
            <v-btn block large color="black lighten-2 white--text">SEND</v-btn>
          </div>

          <div class="invite-link">
            <v-btn block large color="black lighten-2 white--text">
              Copy Invite Link
            </v-btn>
          </div>
        </v-col>

        <v-col>
          <div class="invites">
            <div class="invites-summary">
              <div>
                <span>Accepted {{ invitesAccepted }}</span>
                <span>Invited {{ invitesSent }}</span>
              </div>
            </div>
            <div class="invites-list">
              <div class="invite" :key="`invite-${i}`" v-for="(invite, i) in invites">
                <div class="avatar">
                  <v-icon v-if="!invite.avatar" size="35" color="grey darken-3" :disabled="!invite.accepted">
                    far fa-user-circle
                  </v-icon>
                </div>

                <div :class="['name', !invite.accepted && 'disabled']">
                  {{invite.name}}
                </div>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>

      
    </v-container>
  </div>
</template>

<script>
import ProfileHeader from './ProfileHeader';

const text = "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quo, placeat veritatis ut expedita esse voluptatem voluptate repudiandae reprehenderit, iure magnam ipsam ex nobis commodi maiores quibusdam iusto ea quae natus eligendi facilis dolores consequatur totam architecto. Cumque blanditiis quae dignissimos cupiditate in. Vero fuga, sint optio inventore dolores distinctio cumque.";

export default {
  components: { ProfileHeader },
  data() {
    return {
      current: 0,
      total: 0,
      invites: [

      ]
    }
  },
  mounted() {
    this.total = Math.floor(Math.random() * 100);
    this.current = Math.floor(Math.random() * this.total);

    const length = Math.floor(Math.random() * 40);

    const invites = text
      .replace('.', '')
      .replace(',', '')
      .split(' ')
      .filter(name => name.length < 8)
      .map(name => ({ name, accepted: Math.random() > 0.5 }))

    for (let i = 0; i < length; i++) {
      this.invites.push(invites[Math.floor(Math.random() * invites.length)]);
    }
  },
  computed: {
    invitesAccepted() {
      return this.invites.reduce((acc, e) => acc + (e.accepted ? 1 : 0), 0);
    },
    invitesSent() {
      return this.invites.reduce((acc, e) => acc + (e.accepted ? 0 : 1), 0);
    }
  }
}
</script>

<style lang="scss" scoped>
.invite-field {
  padding: 1rem 0;

  & > *:first-child {
    margin-bottom: 0.5rem;
  }

  &:first-child {
    padding-top: 0;
  }
}



.invites {
  position: relative;

  height: 100%;

  border-radius: 0.25rem;
  border: solid 2px #ccc;
}

.invites-summary {
  position: absolute;

  top: -15px;

  margin: auto;

  width: 100%;

  display: flex;
  flex-flow: row nowrap;

  justify-content: center;
  align-items: center;

  div {
    background-color: #fff;

    padding: 0 0.5rem;
  } 

  span {
    text-align: center;

    padding: 0 0.5rem;
  }

}

.invites-list {
  display: grid;

  grid-template-columns: repeat(7, 1fr);
  grid-auto-rows: 100px;

  place-content: center;

  padding: 1rem;
}

.invite {
  display: flex;
  flex-flow: column nowrap;

  align-items: center;

  & .name.disabled {
    color: rgba(0,0,0,0.38);
  }
}
</style>